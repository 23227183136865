import { useState } from "react";

const PrivacyPolicyComponent = () => {

  const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] = useState(false);

  const togglePrivacyPolicyModal = () => {
    setIsPrivacyPolicyModalOpen(!isPrivacyPolicyModalOpen);
  };

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      togglePrivacyPolicyModal();
    }
  };

  return (
    <div className="policy">
      <p onClick={togglePrivacyPolicyModal}>
        個人情報保護方針
      </p>

      {isPrivacyPolicyModalOpen && (
        <div className="policy-modal" onClick={handleOutsideClick}>
          <div className="policy-modal-content" onClick={(e) => e.stopPropagation()}>
            <iframe
              src="https://medirom-wellness.co.jp/privacy-policy/"
              title="個人情報保護報"
            ></iframe>
            <button onClick={togglePrivacyPolicyModal}>
              閉じる
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrivacyPolicyComponent;