import { useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const InterviewComponent= () => {
  const swiperRef = useRef<SwiperCore | null>(null);

  const handlePrevClick = () => {
    if(swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNextClick = () => {
    if(swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  type InterviewImage = {
    pc: string;
    sp: string;
    name: string;
    nameColor: string;
    entry_year: string;
    entryYearColor: string;
    number: string;
    catch_copy: string;
    catchCopyColor: string;
    hoverColor: string;
    url: string;
  };
  
  const interviewImages: InterviewImage[] = [
    {
      pc: '/img/recruit/interview/interview-01-pc.jpg',
      sp: '/img/recruit/interview/interview-01-sp.jpg',
      name: 'M.W',
      nameColor: '#9CC24F',
      entry_year: '2023年入社',
      entryYearColor: '#9CC24F',
      number: 'No.01',
      catch_copy: '入社4ヶ月で、\n憧れの店長を目指す',
      catchCopyColor: '#377507',
      hoverColor: '#9CC24F',
      url: 'https://reraku.jp/column/YT94rxxKZU',
    },
    {
      pc: '/img/recruit/interview/interview-02-pc.jpg',
      sp: '/img/recruit/interview/interview-02-sp.jpg',
      name: 'S.K',
      nameColor: '#9CC24F',
      entry_year: '2018年入社',
      entryYearColor: '#9CC24F',
      number: 'No.02',
      catch_copy: 'アルバイトから正社員に\nステップアップ',
      catchCopyColor: '#377507',
      hoverColor: '#9CC24F',
      url: 'https://reraku.jp/column/GXGn5WFxFQ',
    },
    {
      pc: '/img/recruit/interview/interview-03-pc.jpg',
      sp: '/img/recruit/interview/interview-03-sp.jpg',
      name: 'M.S',
      nameColor: '#9CC24F',
      entry_year: '2017年入社',
      entryYearColor: '#9CC24F',
      number: 'No.03',
      catch_copy: '店舗代表として、\n社内コンテストで入賞',
      catchCopyColor: '#377507',
      hoverColor: '#9CC24F',
      url: 'https://reraku.jp/column/y31ljxKYOk',
    },
    {
      pc: '/img/recruit/interview/interview-04-pc.jpg',
      sp: '/img/recruit/interview/interview-04-sp.jpg',
      name: 'N.H',
      nameColor: '#9CC24F',
      entry_year: '2015年入社',
      entryYearColor: '#9CC24F',
      number: 'No.04',
      catch_copy: 'セラピストをしながら、\n現場講師の役割も',
      catchCopyColor: '#377507',
      hoverColor: '#9CC24F',
      url: 'https://reraku.jp/column/asQHX9PKvE',
    },
    {
      pc: '/img/recruit/interview/interview-05-pc.jpg',
      sp: '/img/recruit/interview/interview-05-sp.jpg',
      name: 'S.A',
      nameColor: '#9CC24F',
      entry_year: '2019年入社',
      entryYearColor: '#9CC24F',
      number: 'No.05',
      catch_copy: '優秀セラピストの\nサービスに対する想い',
      catchCopyColor: '#377507',
      hoverColor: '#9CC24F',
      url: 'https://reraku.jp/column/a915JOzvZz',
    },
    {
      pc: '/img/recruit/interview/interview-06-pc.jpg',
      sp: '/img/recruit/interview/interview-06-sp.jpg',
      name: 'T.H',
      nameColor: '#FFB31E',
      entry_year: '2022年入社',
      entryYearColor: '#FFB31E',
      number: 'No.06',
      catch_copy: '飲食業界から\nセラピストに転身',
      catchCopyColor: '#FFB31E',
      hoverColor: '#FFB31E',
      url: 'https://reraku.jp/column/aLkPXPWuZL',
    },
    {
      pc: '/img/recruit/interview/interview-07-pc.jpg',
      sp: '/img/recruit/interview/interview-07-sp.jpg',
      name: 'S.K',
      nameColor: '#9CC24F',
      entry_year: '2022年入社',
      entryYearColor: '#9CC24F',
      number: 'No.07',
      catch_copy: 'セラピストとして、\n地元を盛り上げる',
      catchCopyColor: '#377507',
      hoverColor: '#9CC24F',
      url: 'https://reraku.jp/column/7qnzwpkzy4',
    },
    {
      pc: '/img/recruit/interview/interview-08-pc.jpg',
      sp: '/img/recruit/interview/interview-08-sp.jpg',
      name: 'N.Y',
      nameColor: '#9CC24F',
      entry_year: '2021年入社',
      entryYearColor: '#9CC24F',
      number: 'No.08',
      catch_copy: '契約社員に\nステップアップ',
      catchCopyColor: '#377507',
      hoverColor: '#9CC24F',
      url: 'https://reraku.jp/column/EF2AG4OBxb',
    },
    {
      pc: '/img/recruit/interview/interview-09-pc.jpg',
      sp: '/img/recruit/interview/interview-09-sp.jpg',
      name: 'H.A',
      nameColor: '#9CC24F',
      entry_year: '2022年入社',
      entryYearColor: '#9CC24F',
      number: 'No.09',
      catch_copy: '母の病気がきっかけで、\nセラピストの道へ',
      catchCopyColor: '#377507',
      hoverColor: '#9CC24F',
      url: 'https://reraku.jp/column/3StbAy9A7h',
    },
    {
      pc: '/img/recruit/interview/interview-10-pc.jpg',
      sp: '/img/recruit/interview/interview-10-sp.jpg',
      name: 'N.S',
      nameColor: '#FFB31E',
      entry_year: '2015年入社',
      entryYearColor: '#FFB31E',
      number: 'No.10',
      catch_copy: '男性セラピストで、\n指名1位を獲得',
      catchCopyColor: '#FFB31E',
      hoverColor: '#FFB31E',
      url: 'https://reraku.jp/column/VFl6CRTetn',
    },
  ];

  return (
    <div className="recruit-interview">
      <h2 className="interview-title-pc"><img src="/img/recruit/interview/interview-title-pc.png" alt="インタビュー" /></h2>
      <h2 className="interview-title-sp"><img src="/img/recruit/interview/interview-title-sp.png" alt="インタビュー" /></h2>

      <div className="interview-swiper-container">
        <Swiper
          className="interview-swiper"
          spaceBetween={16}
          slidesPerView={2}
          loop={true}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[Navigation, Pagination, Autoplay]}
          pagination={{
            el: '.custom-swiper-pagination-sp',
            clickable: true,
          }}
          autoplay={{ delay: 5000 }}
          breakpoints={{
            769: {
              spaceBetween: 16,
              slidesPerView: 4,
              navigation: false,
            },
          }}
        >
          {interviewImages.map((image, index) => (
            <SwiperSlide
              key={index}
              className={image.url ? '' : 'coming-soon'}
            >
              <a
                target="_blank"
                rel="noreferrer"
                href={image.url ? image.url : undefined}
              >
                <div
                  className={`interview-swiper-overlay ${image.url ? '' : 'coming-soon'}`}
                  css={{
                    ":hover": {
                      backgroundColor: image.hoverColor,

                      img: {
                        opacity: 0.3,
                      },
                    }
                  }}
                >
                  <img src={image.pc} alt={`インタビュー${index + 1}`} className="interview-swiper-pc" />
                  <img src={image.sp} alt={`インタビュー${index + 1}`} className="interview-swiper-sp"/>
                  <p className="interview-number">{image.number}</p>
                  <p className="interview-detail">Read more</p>
                </div>
                <div className="interview-info">
                  <p
                    className="interview-catch-copy"
                    css={{ color: image.catchCopyColor }}
                  >
                    {image.catch_copy}
                  </p>
                  <div
                    className="entry-details"
                    css={{
                      ':hover .interview-entry-year': {
                        color: image.entryYearColor
                      },
                      ':hover .interview-name': {
                        color: image.nameColor
                      } 
                    }}
                  >
                    <p className="interview-entry-year">{image.entry_year}</p>
                    <h3 className="interview-name">{image.name}</h3>
                  </div>
                </div>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="navigation-pagination-wrapper-sp">
          <img
            src="/img/recruit/recruit-chevron-left.svg"
            alt="前へ"
            className="swiper-button-prev-sp"
            onClick={handlePrevClick}
          />
          <div className="swiper-pagination custom-swiper-pagination-sp"></div>
          <img
            src="/img/recruit/recruit-chevron-right.svg"
            alt="次へ"
            className="swiper-button-next-sp"
            onClick={handleNextClick}
          />
        </div>

        <div className="navigation-pagination-wrapper-pc">
          <img
            src="/img/recruit/recruit-chevron-left.svg"
            alt="前へ"
            className="swiper-button-prev-pc"
            onClick={handlePrevClick}
          />
          <img
            src="/img/recruit/recruit-chevron-right.svg"
            alt="次へ"
            className="swiper-button-next-pc"
            onClick={handleNextClick}
          />
        </div>
      </div>
    </div>
  );
};

export default InterviewComponent;